import VibeTribe from './../assets/images/vibetribe-md.jpg'
import Codelingo from './../assets/images/codelingo-md.jpg'
import TigerWines from './../assets/images/tigerwines-md.jpg'

const data = [
  {
    title: 'Vibe Tribe',
    github: 'https://github.com/max-deathray/tribute-react-apollo',
    description: `A Pinterest clone built with React, Apollo Client, GraphQL, GraphQL-Yoga & Prisma.`,
    image: VibeTribe,
  },
  {
    title: 'Codelingo',
    website: 'https://www.codelingoapp.com/',
    github: 'https://github.com/code-lingo/code-lingo',
    description: ` A mobile-optimized app for learning to code, inspired by Duolingo. Built with React, Redux & Firebase over two weeks by a team of four.`,
    image: Codelingo,
  },
  {
    title: 'Tiger Wines',
    website: 'http://tigerwines.herokuapp.com/',
    github: 'https://github.com/Tiger-Earth/GraceShopper',
    description: `E-commerce SPA built in a week by a team of four using React, Redux, Axios, Express, Sequelize, PostgreSQL & Material UI`,
    image: TigerWines,
  },
]

export default data
